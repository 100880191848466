var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-expansion-panels',{staticClass:"mb-6"},_vm._l((_vm.items),function(item){return _c('v-expansion-panel',{key:item.type},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"11"}},[_c('p',[_vm._v(" Liste des templates pour les mails concernant les objets de type "),_c('b',[_vm._v(_vm._s(item.type))])])]),_c('EmailTemplatesCounter',{attrs:{"customSearch":[{ 'meta.typeParent': item.type }]}})],1)],1),_c('v-expansion-panel-content',[_c('v-expansion-panels',{staticClass:"mb-6"},_vm._l((item.categories),function(categorie){return _c('v-expansion-panel',{key:categorie.value},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"11"}},[_c('p',[_vm._v(" Liste des templates pour les mails concernant "),_c('b',[_vm._v(_vm._s(item.type)+" - "+_vm._s(categorie.text))])])]),_c('EmailTemplatesCounter',{attrs:{"customSearch":[
                    { 'meta.typeParent': item.type },
                    { 'meta.categorie': categorie.value },
                  ]}})],1)],1),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',[_c('EmailTemplatesObject',{attrs:{"meta":{
                      typeParent: item.type,
                      categorie: categorie.value,
                    }}})],1)],1)],1)],1)}),1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }