<template>
  <div v-show="!fromFavorite">
    <v-select
      v-model="selectedCategorie"
      :items="items"
      item-text="label"
      return-object
      label="Type de document à générer"
      prepend-icon="mdi-format-list-bulleted-type"
    />
    <v-select
      v-if="selectedCategorie"
      v-model="selectedTemplate"
      :items="selectedCategorie.fichiers"
      item-text="displayName"
      label="Template à utiliser"
      prepend-icon="mdi-file-compare"
      return-object
      append-outer-icon="mdi-application-braces-outline"
      @click:append-outer="showData"
    />

    <v-row>
      <v-col class="text-center">
        <v-btn
          color="primary"
          @click="generate()"
          class="mr-2"
          :disabled="selectedTemplate ? false : true"
        >
          <v-icon class="mr-2">mdi-file-word-box</v-icon>
          Générer au format DOCX
        </v-btn>
        <v-btn
          color="primary"
          @click="generate(true)"
          :disabled="selectedTemplate ? false : true"
        >
          <v-icon class="mr-2">mdi-file-pdf-box</v-icon>
          Générer au format PDF
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from "lodash";

import {
  MUTATION_fichierFromTemplateCreate,
  MUTATION_downloadURLCreate,
} from "./graphql/mutations";
import { QUERY_fichiers } from "./graphql/queries";

export default {
  name: "FichierMaker",

  props: {
    data: { type: Object },
    fromFavorite: { type: Boolean, default: false },
    labelTemplates: { type: String, default: undefined },
    meta: { type: Object },
    typeParent: { type: String, default: "" }
  },

  data: () => ({
    forcedData: undefined,
    forcedMeta: undefined,
    selectedCategorie: undefined,
    selectedTemplate: "",
    withAlerte: true,
    fichiers: []
  }),

  computed: {
    items() {
      let toReturn = [];

      if (this.fichiers) {
        this.fichiers.forEach((fichier) => {
          if (fichier.meta) {
            fichier.meta.forEach((fichierMeta) => {
              let indexOf = _.findIndex(toReturn, {
                label: fichierMeta.categorie,
              });
              if (indexOf < 0) {
                let categorie = {
                  label: fichierMeta.categorie,
                  fichiers: [fichier],
                };
                toReturn.push(categorie);
              } else {
                toReturn[indexOf].fichiers.push(fichier);
              }
            });
          }
        });
      }
      if (toReturn.length > 0) {
        this.$emit("show");
      }
      
      toReturn.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });

      return toReturn;
    },
  },

  methods: {
    async init() {
      this.fichiers = [];

      let res = await this.$apollo
        .query({
          query: QUERY_fichiers,
          variables: {
            pagination: {
              limit: 0,
              filter: {
                customSearch: [
                  { "meta.typeParent": this.typeParent },
                  { "meta.template": true },
                  { favorite: this.fromFavorite ? true : "$all" },
                ],
              },
            },
          },
          fetchPolicy: "no-cache",
        })

        this.fichiers = res.data.fichiers;
    },

    async generate(atPDF = false) {
      await this.init();
      if (this.fromFavorite) {
        if(this.fichiers.length == 0) {
          this.$store.commit("addAlert", {
            type: "warning",
            text: `Il n'y a aucun template favori pour le type ${this.typeParent}`,
          });
          return;
        }
        this.selectedTemplate = this.fichiers[0];
      }

      this.$apollo
        .mutate({
          mutation: MUTATION_fichierFromTemplateCreate,
          variables: {
            input: {
              idTemplate: this.selectedTemplate.id,
              data: this.forcedData ? this.forcedData : this.data,
              atPDF: atPDF,

              meta: this.forcedMeta ? this.forcedMeta : this.meta,
            },
          },
        })
        .then((__) => {
          this.$root.$emit("fichiersStockagesUpdateFichiers");
          let fichier = __.data.fichierFromTemplateCreate[0];
          
          if(this.withAlerte) {
            this.$store.commit("addAlert", {
              type: "info",
              text: "Le document est généré et ajouté à la liste des fichiers",
            });
          }
          if (this.fromFavorite) {
            this.$apollo
              .mutate({
                mutation: MUTATION_downloadURLCreate,
                variables: {
                  id: fichier.id,
                },
                fetchPolicy: "no-cache",
              })
              .then((data) => {
                let fileUrl = data.data.downloadURLCreate.url;
                //window.location.assign(fileUrl);
                let link = document.createElement("a"); //create 'a' element
                link.setAttribute("href", fileUrl); //replace "file" with link to file you want to download
                link.setAttribute("target", "_blank");
                link.setAttribute("download", fileUrl); // replace "file" here too
                link.click(); //virtually click <a> element to initiate download
              });
          }
        });
    },

    showData() {
      console.info(this.data);
    },
  },

  async mounted() {
    await this.init();
  }
};
</script>
