var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.items),function(item,index){return [(
        (!item.acl || _vm.acl(item.acl)) &&
        (!item.aclMeta || _vm.aclMeta(item.aclMeta))
      )?[(_vm.editMenuOrder)?[_c('div',{key:'btn' + item.title,staticStyle:{"float":"left"}},[_c('v-btn',{attrs:{"color":"primary","icon":"","x-small":"","dark":"","disabled":index > 0 ? false : true},on:{"click":function($event){return _vm.moveMenuItem('up', index)}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1),_c('v-btn',{attrs:{"color":"primary","icon":"","x-small":"","dark":"","disabled":index == _vm.items.length - 1 ? true : false},on:{"click":function($event){return _vm.moveMenuItem('down', index)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1)]:_vm._e(),(item.children)?[(_vm.show(item))?_c('v-list-group',{key:'group' + item.title,attrs:{"prepend-icon":item.icon,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(_vm._s(item.title))])]},proxy:true}],null,true)},[_vm._l((item.children),function(subitem){return [(
                (!subitem.acl || _vm.acl(subitem.acl)) &&
                (!subitem.aclMeta || _vm.aclMeta(subitem.aclMeta))
              )?_c('MainMenuItem',{key:subitem.title,staticClass:"groupItems",attrs:{"item":subitem}}):_vm._e()]})],2):_vm._e()]:(_vm.show(item))?_c('MainMenuItem',{key:item.title,attrs:{"item":item}}):_vm._e()]:_vm._e()]}),(_vm.editMenuOrder)?[_c('div',{attrs:{"align":"center","justify":"center"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.saveMenuOrder}},[_vm._v("Terminer")])],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }