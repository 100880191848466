<template>
  <SelectorLayout
    :addAction="addAction"
    :disabled="disabled"
    @fetch="fetch"
    :label="label"
    :prepend-icon="prependIcon"
    ref="selector"
    v-model="item"
    :multiple="multiple"
  >
    <template v-slot:selection="data">
      <ModeleObject
        action="none"
        @click="data.select"
        :input-value="data.selected"
        :modeleProp="data.item"
        size="xs"
        v-bind="data.attrs"
      />
    </template>

    <template v-slot:item="data">
      <ModeleObject :modeleProp="data.item" action="none" />
    </template>
  </SelectorLayout>
</template>

<script>
import { QUERY_modeles } from "./graphql/queries";

import ModeleObject from "./ModeleObject.vue";
import SelectorLayout from "@/components/COMPONENTS-skeleton/Layouts/SelectorLayout.vue";

export default {
  name: "ModeleSelector",

  components: {
    ModeleObject,
    SelectorLayout,
  },

  props: {
    addAction: { type: String, default: "/assets/modeles/add" },
    disabled: { type: Boolean, default: false },
    errorMessage: { type: String },
    label: { type: String, default: "Modèle" },
    marque: null,
    modeleByMarque: { type: Object, default: null },
    multiple: { type: Boolean, default: false },
    prependIcon: { type: String, default: "mdi-barcode-scan" },
    value: null,
  },

  data: () => ({
    item: null,
  }),

  mounted() {
    this.item = this.value && this.value.id ? this.value.id : this.value;
    this.fetch();
  },

  methods: {
    // Fetch les marques en fonction du filtre
    fetch() {
      this.$refs.selector.loading = true;
      let pagination = JSON.parse(
        JSON.stringify(this.$refs.selector.pagination)
      );
      
      if (this.marque) {
        pagination.filter.customSearch.push({
          marque: this.marque,
        });
      }

      if(this.item && !Array.isArray(this.item)) pagination.filter.customSearch.push({ _id: this.item })

      this.$apollo
        .query({
          query: QUERY_modeles,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.selector.items = data.modeles;
          this.$refs.selector.loading = false;
        });
    },
  },

  watch: {
    value: {
      deep: true,
      handler(val) {
        this.item = val && val.id ? val.id : val;
        this.fetch();
      },
    },
    
    marque(val) {
      if (val) {
        this.fetch();
      }
    },
    
    item: {
      deep: true,
      handler(val) {
        if (val === null) {
          this.$emit("input", null);
        } else {
          this.$emit("input", val);
        }
      },
    },
  },
};
</script>