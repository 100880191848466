var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ViewLayout',{attrs:{"tabs":_vm.tabs},scopedSlots:_vm._u([{key:"parametres",fn:function(){return [_c('FormLayout',{attrs:{"icon":"mdi-domain","typeItem":"les paramètres de l'organisation","item":_vm.input},on:{"modifier":_vm.modifier,"reinitialiser":_vm.init}},[_c('v-row',{staticClass:"ma-4",attrs:{"justify":"center","no-gutters":""}},[_c('FichierLogo',{attrs:{"size":"128","meta":_vm.meta},model:{value:(_vm.input.logo),callback:function ($$v) {_vm.$set(_vm.input, "logo", $$v)},expression:"input.logo"}})],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Nom de l'organisation","prepend-icon":"mdi-domain","error-messages":_vm.formsErrors.organisationName,"rules":[(value) => value.length <= 20 || 'Max 20 caractères']},model:{value:(_vm.input.organisationName),callback:function ($$v) {_vm.$set(_vm.input, "organisationName", $$v)},expression:"input.organisationName"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Nom de l'application","prepend-icon":"mdi-form-textbox"},model:{value:(_vm.input.applicationName),callback:function ($$v) {_vm.$set(_vm.input, "applicationName", $$v)},expression:"input.applicationName"}})],1)],1),(!_vm.organisationID)?_c('v-row',[_c('v-col',[_c('v-input',{attrs:{"messages":[
              'Changer l\'organisation du menu pour l\'ensemble de l\'organisation',
            ],"prepend-icon":"mdi-format-list-bulleted-type"}},[_c('v-btn',{attrs:{"small":""},on:{"click":_vm.orderMenu}},[_vm._v(" Réorganiser le menu ")])],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',{staticClass:"pl-0"},[_vm._v(" Nombre maximum d'équipements par utilisateur ")]),_c('v-slider',{attrs:{"max":"10","min":"1","thumb-label":"always"},model:{value:(_vm.input.nbEquipementsMax),callback:function ($$v) {_vm.$set(_vm.input, "nbEquipementsMax", $$v)},expression:"input.nbEquipementsMax"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}})],1),(!_vm.organisationID)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',{staticClass:"pl-0"},[_vm._v(" Background de l'organisation ")]),_c('TypeFichierForm',{staticClass:"ma-4",attrs:{"authorizedExtensions":['png', 'jpg', 'jpeg', 'webp'],"useCategory":false,"securise":false,"meta":{
              typeParent:
                'AuthentificationHabilitationOrganisationParametres',
              idParent: _vm.authentificationHabilitationOrganisationParametres.id,
              nameParent:
                _vm.authentificationHabilitationOrganisationParametres.organisationName &&
                _vm.authentificationHabilitationOrganisationParametres
                  .organisationName.length <= 20
                  ? _vm.authentificationHabilitationOrganisationParametres.organisationName
                  : undefined,
              typeFichier: 'background',
              isPublic: true,
            }}}),_c('v-carousel',{attrs:{"hide-delimiters":""},on:{"change":_vm.fetchBackgroundsUrl}},_vm._l((_vm.carouselPages),function(n){return _c('v-carousel-item',{key:n},[_c('v-row',_vm._l((_vm.urlsBackgrounds),function(item,index){return _c('v-col',{key:index},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',[_vm._v(" "+_vm._s(hover)+" "),_c('v-img',{attrs:{"aspect-ratio":16/9,"width":"200","src":item.url}})],1)]}}],null,true)})],1)}),1)],1)}),1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',{staticClass:"pl-0"},[_vm._v(" Changer la couleur de l'application ")]),_c('v-btn',{staticClass:"mt-12",attrs:{"color":_vm.input.color,"large":"","outlined:":"","true":""},on:{"click":function($event){return _vm.$router.push({ path: `/authentification-habilitation/parametres/color-selector?popup=true&componentParent=component-${_vm._uid}` })}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',{staticClass:"pl-0"},[_vm._v(" Ajouter des URLS personnalisées ")]),_c('v-text-field',{attrs:{"prefix":"https://","label":"Ajout d'une URL personnalisée","persistent-hint":"","append-outer-icon":"mdi-plus-circle","rules":[(value) => _vm.input.urls.indexOf(value) == -1 || 'L\'URL est déjà ajoutée'],"error-messages":_vm.formsErrors.authentificationHabilitationOrganisationParametresUrlDuplicate 
              ? _vm.formsErrors.authentificationHabilitationOrganisationParametresUrlDuplicate
              : _vm.formsErrors.authentificationHabilitationOrganisationParametresUrlCharacters 
              ? _vm.formsErrors.authentificationHabilitationOrganisationParametresUrlCharacters
              : ''},on:{"click:append-outer":_vm.addURL},model:{value:(_vm.urlCourante),callback:function ($$v) {_vm.urlCourante=$$v},expression:"urlCourante"}}),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" URL ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Actions ")])])]),_c('tbody',_vm._l((_vm.input.urls),function(url){return _c('tr',{key:url},[_c('td',[_vm._v(_vm._s(url))]),_c('td',[_c('v-icon',{on:{"click":function($event){return _vm.editUrl(url)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteUrl(url)}}},[_vm._v(" mdi-delete ")])],1)])}),0)]},proxy:true}])})],1)],1)],1)]},proxy:true},{key:"mails",fn:function(){return [_c('v-alert',{attrs:{"text":"","color":"info"}},[_c('div',[_vm._v("Editez ici vos templates d'email servant aux notifications")])]),_c('EmailTemplatesList',{attrs:{"items":_vm.emailTemplatesAvailable,"typeParent":"AuthentificationHabilitationOrganisationParametres","idParent":_vm.input.id}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }