<template>
  <div>
    <ActionLayout
      :actions="actions"
      :size="size"
      :action="action"
      @fetch="$emit('fetch')"
      :mainIcon="mainIcon"
    />
  </div>
</template>

<script>
import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";
import {
  MUTATION_modeleDelete,
  MUTATION_modeleUpdate,
} from "./graphql/mutations.js";
export default {
  name: "ModeleActions",
  components: {
    ActionLayout,
  },

  props: {
    withView: { type: Boolean, default: true },
    mainIcon: { type: String, default: "mdi-barcode-scan" },
    object: { type: [Array, Object] },
    size: { type: String, default: "md" },
    action: { type: String, default: "router" },
    multiple: { type: Boolean, default: false },
  },

  computed: {
    actions() {
      return [
        {
          text: "Editer",
          icon: "mdi-pencil",
          path: "/assets/modeles/edit/" + this.object.id,
          acl: "/assets/assets/edit",
          color: "",
          conditions: { multiple: false, isActif: true },
        },
        {
          text: "Supprimer",
          icon: "mdi-delete",
          method: "editIsActif",
          acl: "/assets/modeles/deleted",
          color: "error",
          conditions: { multiple: true, isActif: true },
        },
        {
          text: "Restauration",
          icon: "mdi-restore",
          method: "editIsActif",
          acl: "/assets/modeles/edit",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Supprimer définitivement",
          icon: "mdi-delete",
          method: "delete",
          acl: "/assets/modeles/definitive-delete",
          color: "error",
          conditions: { multiple: false, isActif: false },
        },
        {
          text: "Visualiser",
          icon: "mdi-eye",
          path: "/assets/modeles/view/" + this.object.id,
          acl: "/assets/assets/view",
          color: "",
          conditions: {},
        },
      ];
    },
  },
  created() {
    this.$root.$on("deleteModele", this.deleteModele);
    this.$root.$on("editModele", this.editModele);
  },

  methods: {
    /**
     * Supprimer une modele grâce à son id
     */
    deleteModele(id) {
      this.$apollo
        .mutate({
          mutation: MUTATION_modeleDelete,
          variables: { id: id },
        })
        .then(() => {
          this.$emit("fetch");
        });
    },

    /**
     * Fonction à avoir dans tous les composants Actions (infos à changer selon le composant)
     * Permet de lancer la popup de validation
     *
     * obj : l'objet a supprimé
     */
    delete(obj) {
      // infos qui s'afficheront dans le popup
      let toValid = {
        titre: "Suppression définitive d'une modèle",
        texte: `Êtes-vous sûr de vouloir supprimer définitivement le modèle ${obj.nom} ?`,
        type: "warning",
        toReturn: {
          function: "deleteModele", // cette fonction change en fonction du composant (ex: PersonneActions.vue ça serait deletePersonne,
          //qu'il faut créer à la place de deleteMarque)
          arg: obj.id,
        },
      };

      // Envoi l'info pour ouvrir la popup
      this.$root.$emit("popupValidation", toValid);
    },

    /**
     * Fonction qui édite une Marque
     *
     * obj : l'objet à éditer
     */
    async editModele(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      this.$emit("loading");
      // Selon le schéma, il est obligé d'avoir le siren et la raison sociale dans EntepriseInput
      // Bien vérifier dans le schéma si jamais il y a une erreur du style "$input etc.."
      for (let item of obj) {
        let modele = {
          id: item.id,
          isActif: !item.isActif,
        };
        await this.$apollo.mutate({
          mutation: MUTATION_modeleUpdate,
          variables: {
            input: modele,
          },
        });
      }
      this.$emit("fetch");
    },

    /**
     * Fonction à avoir dans tous les composants (infos à changer)
     */
    editIsActif(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      let toValid = {
        titre: obj[0].isActif
          ? "Suppression de modèle(s)"
          : "Restauration de modèle(s)",
        texte: obj[0].isActif
          ? `Êtes-vous sûr de vouloir supprimer ${obj.length} modèle(s) ? ` +
            'Ils seront toujours présents dans l\'onglet "Corbeille"'
          : `Êtes-vous sûr de vouloir restaurer ${obj.length} modèle(s) ?`,
        type: "warning",
        toReturn: {
          function: "editModele", // à changer selon le composant, comme deleteMarque
          arg: obj,
        },
      };

      this.$root.$emit("popupValidation", toValid);
    },
  },
};
</script>