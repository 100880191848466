<template>
  <ViewLayout :tabs="tabs" :item="affaire" :data="fichierMakerData">
    <template v-slot:affaire>
      <AffaireActions
        @fetch="$refs.demandeAccordList.init()"
        :object="affaire"
        :withView="false"
        size="xl"
      />
      <v-row>
        <v-col>
          <AffaireObject :affaireProp="affaire" size="xl" />
        </v-col>
        <v-col>
          <AffaireSimulation :affaire="affaire" size="xl" />
        </v-col>
      </v-row>
    </template>
    <template v-slot:demandeAccords>
      <AffaireActions
        @fetch="$refs.demandeAccordList.init()"
        :object="affaire"
        :withView="false"
        size="xl"
      />
      <v-row>
        <v-col>
          <DemandeAccordsList
            ref="demandeAccordList"
            title="Demande d'accords pour l'affaire"
            :multiple="false"
            :customSearch="[{ _id: demandeAccordsID }]"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:devisFactureFournisseurs>
      <v-row>
        <v-col>
          <DevisFournisseursList
            :noFilter="true"
            :multiple="false"
            :customSearch="[{ _id: devisFournisseursID }]"
          />
        </v-col>
        <v-col>
          <FactureFournisseursList
            :noFilter="true"
            :multiple="false"
            :customSearch="[{ _id: facturesFournisseursID }]"
          />
        </v-col>
      </v-row>
    </template>
  </ViewLayout>
</template>

<script>
import { financementToString } from "./helpers/financementToString";
import { QUERY_affaire } from "./graphql/queries";

import AffaireActions from "./AffaireActions.vue";
import AffaireObject from "./AffaireObject.vue";
import AffaireSimulation from "./AffaireSimulation.vue";
import DemandeAccordsList from "./DemandeAccordsList.vue";
import DevisFournisseursList from "@/components/COMPONENTS-devis-facture/DevisFournisseursList.vue";
import FactureFournisseursList from "@/components/COMPONENTS-devis-facture/FactureFournisseursList.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "AffaireView",

  components: {
    AffaireActions,
    AffaireObject,
    AffaireSimulation,
    DemandeAccordsList,
    DevisFournisseursList,
    FactureFournisseursList,
    ViewLayout,
  },

  props: {
    id: {
      type: String,
    },
  },

  data: () => ({
    affaire: {},
    tabs: [
      { icon: "mdi-briefcase-clock", displayName: "Affaire", name: "affaire" },
      {
        icon: "mdi-cash-check",
        displayName: "Demandes d'accord",
        name: "demandeAccords",
      },
      {
        icon: "mdi-file-compare",
        displayName: "Devis - Factures",
        name: "devisFactureFournisseurs",
      },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),

  computed: {
    demandeAccordsID() {
      let IDs = [];
      if (this.affaire.demandeAccords && this.affaire.demandeAccords[0]) {
        this.affaire.demandeAccords.forEach((devis) => {
          IDs.push(devis.id);
        });
      }

      return IDs;
    },

    devisFournisseursID() {
      let IDs = [];
      if (this.affaire.devisFournisseurs && this.affaire.devisFournisseurs[0]) {
        this.affaire.devisFournisseurs.forEach((devis) => {
          IDs.push(devis.id);
        });
      }

      return IDs;
    },

    facturesFournisseursID() {
      let IDs = [];
      if (
        this.affaire.facturesFournisseurs &&
        this.affaire.facturesFournisseurs[0]
      ) {
        this.affaire.facturesFournisseurs.forEach((facture) => {
          IDs.push(facture.id);
        });
      }

      return IDs;
    },

    fichierMakerData() {
      let toReturn = { ...this.affaire };
      toReturn.financement = financementToString(toReturn.financement);
      return toReturn;
    },
  },

  apollo: {
    affaire: {
      query: QUERY_affaire,
      fetchPolicy: "no-cache",
      variables() {
        let idAffaire = this.$route.params.id ? this.$route.params.id : this.id;
        return {
          id: idAffaire,
        };
      },
    },
  },

  mounted: function () {},
};
</script>