<template>
  <FormLayout icon="mdi-card-account-mail-outline" @ajouter="ajouter" @modifier="modifier" @reinitialiser="init"
    typeItem="une personne" :item="input" :disabled="input.nom && input.prenom ? false : true">
    <v-row>
      <v-col md="4" cols="12" align="center">
        <FichierLogo v-model="input.avatar" :text="input.prenom + ' ' + input.nom" :size="120" />
      </v-col>
      <v-col>
        <v-text-field v-model="input.prenom" name="given-name" :error-messages="formsErrors.personnePrenom"
          label="Prénom" required />
        <v-text-field v-model="input.nom" name="family-name" :error-messages="formsErrors.personneNom"
          label="Nom de famille" required />
        <DateInput v-model="input.dateNaissance" label="Date de naissance"
          :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)" />
        <v-select 
          :items="civilites"
          v-model="input.civilite"
          label="Civilité"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <template v-if="input.adressesMails && input.adressesMails[0]">
          <v-divider></v-divider>
          <v-subheader>Adresses e-mails :</v-subheader>
        </template>
        <template v-for="(mail, index) in input.adressesMails">
          <v-row class="align-item" :key="`mail-${index}`">
            <v-col cols="8">
              <v-text-field v-model="mail.adresse" name="email" :error-messages="
                formsErrors.personneMail
                  ? formsErrors.personneMail[index]
                    ? formsErrors.personneMail[index]
                    : ''
                  : ''
              " label="Adresse de courrier électronique" prepend-icon="mdi-email-outline" />
            </v-col>
            <v-col cols="2" align="center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mt-3" icon :color="mail.newsletter ? 'primary' : 'none'" v-bind="attrs" v-on="on"
                    @click="
                      {
                        mail.newsletter = !mail.newsletter;
                      }
                    ">
                    <v-icon>mdi-newspaper-variant</v-icon>
                  </v-btn>
                </template>
                <span>Recoit la newsletter <br />sur cette adresse</span>
              </v-tooltip>
            </v-col>
            <v-col cols="2" align="center">
              <v-btn class="mt-3" @click="removeEmailField(index)" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-col>
      <v-col>
        <template v-if="input.numerosTelephone && input.numerosTelephone[0]">
          <v-divider></v-divider>
          <v-subheader>Numéros de téléphone :</v-subheader>
        </template>
        <template v-for="(phone, index) in input.numerosTelephone">
          <v-row :key="`phone-${index}`" no-gutters>
            <v-col sm="5" md="3">
              <v-select v-model="phone.indicatif" name="tel-country-code" :hint="`${phone.indicatif.name}`"
                :items="phoneIndicators" :item-text="(item) => item.name + ' : ' + item.dialCode"
                prepend-icon="mdi-phone-outline" persistent-hint return-object>
                <template slot="selection" slot-scope="{ item }">
                  {{ item.flag }} {{ item.dialCode }}
                </template>
              </v-select>
            </v-col>
            <v-col col="1">
              <v-text-field v-model="phone.numero" name="tel-national" :error-messages="
                formsErrors.personneNumeroTelephone
                  ? formsErrors.personneNumeroTelephone[index]
                    ? formsErrors.personneNumeroTelephone[index]
                    : ''
                  : ''
              " label="Numéro de téléphone" />
            </v-col>
            <v-col cols="1" align="center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mt-3" icon :color="phone.demarchage ? 'primary' : 'none'" v-bind="attrs" v-on="on"
                    @click="
                      {
                        phone.demarchage = !phone.demarchage;
                      }
                    ">
                    <v-icon>mdi-headset</v-icon>
                  </v-btn>
                </template>
                <span>Accepte le démarchage téléphonique / SMS</span>
              </v-tooltip>
            </v-col>
            <v-col cols="1" align="center">
              <v-btn class="mt-3" @click="removePhoneField(index)" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>

    <div v-if="input.fonctions && input.fonctions[0]">
      <v-divider></v-divider>
      <v-subheader class="mb-3">Fonctions occupées :</v-subheader>
      <template v-for="(fonction, index) in input.fonctions">
        <v-row :key="`fonction-${index}`">
          <v-col cols="6">
            <PersonneFonctionSelector v-model="fonction.fonction" :error-messages="
              formsErrors.personneFonctionFonction
                ? formsErrors.personneFonctionFonction[index]
                  ? formsErrors.personneFonctionFonction[index]
                  : ''
                : ''
            " />
          </v-col>
          <v-col cols="6">
            <EntrepriseSelector v-model="fonction.entreprise" />
          </v-col>
          <v-col cols="10">
            <EtablissementSelector :disabled="fonction.entreprise ? false : true" :entreprise="
              fonction.entreprise && fonction.entreprise.id
                ? fonction.entreprise.id
                : fonction.entreprise
            " v-model="fonction.etablissement" />
          </v-col>
          <v-col cols="1" align="center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ma-3" icon :color="fonction.pouvoirSignature ? 'primary' : 'none'" v-bind="attrs"
                  v-on="on" @click="
                    {
                      fonction.pouvoirSignature = !fonction.pouvoirSignature;
                    }
                  ">
                  <v-icon>mdi-fountain-pen-tip</v-icon>
                </v-btn>
              </template>
              <span>A le pouvoir de signature</span>
            </v-tooltip>
          </v-col>
          <v-col cols="1" align="center">
            <v-btn class="mt-3" @click="removeFonctionField(index)" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </div>

    <v-card-actions class="pl-0"> </v-card-actions>
    <v-row class="ma-4" justify="center" no-gutters>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="info" v-bind="attrs" v-on="on"> Ajouter plus </v-btn>
        </template>
        <v-list class="d-flex flex-column">
          <v-list-item @click="addEmailField">
            <v-list-item-title>
              <v-icon>mdi-email-outline</v-icon>
              Adresse email
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="addPhoneField">
            <v-list-item-title>
              <v-icon>mdi-phone-outline</v-icon>
              Numéro de téléphone
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="addFonctionField">
            <v-list-item-title>
              <v-icon>mdi-chess-pawn</v-icon> Fonction occupée
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
  </FormLayout>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";

import {
  MUTATION_personneCreate,
  MUTATION_personneUpdate,
} from "./graphql/mutations";
import { QUERY_personne } from "./graphql/queries";

import EntrepriseSelector from "./EntrepriseSelector.vue";
import EtablissementSelector from "./EtablissementSelector.vue";
import FichierLogo from "@/components/COMPONENTS-fichiers-stockages/FichierLogo.vue";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import PersonneFonctionSelector from "./PersonneFonctionSelector.vue";
import DateInput from "@/components/COMPONENTS-skeleton/Inputs/DateInput.vue";
import phoneIndicators from "./helpers/phoneIndicators.json";

export default {
  name: "PersonneForm",

  components: {
    EntrepriseSelector,
    EtablissementSelector,
    FichierLogo,
    PersonneFonctionSelector,
    FormLayout,
    DateInput
  },

  props: {
    componentParent: undefined,
    personneInfos: undefined,
    displayMailForm: {
      type: Boolean,
      default: false
    },
    displayTelForm: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    input: {},
    personne: {
      id: undefined,
      nom: "",
      prenom: "",
      numerosTelephone: [],
      adressesMails: [],
      fonctions: [],
      avatar: {
        id: "",
      },
    },
    phoneIndicators: phoneIndicators,
    civilites: ["Monsieur", "Madame"]
  }),

  computed: {
    ...mapState(["formsErrors"]),
  },

  mounted: function () {
    this.init();
  },

  methods: {
    //Ajouter un champ email au formulaire
    addEmailField() {
      this.input.adressesMails.push({
        adresse: "",
        newsletter: false,
      });
    },

    //Ajouter un champ fonction au formulaire
    addFonctionField() {
      this.input.fonctions.push({
        fonction: "",
        entreprise: "",
        etablissement: "",
      });
    },

    //Ajouter un champ téléphone au formulaire
    addPhoneField() {
      this.input.numerosTelephone.push({
        indicatif: {
          name: "France",
          dialCode: "+33",
          code: "FR",
          flag: "🇫🇷",
        },
        numero: "",
        demarchage: false,
      });
    },

    //Ajouter une personne dans l'annuaire
    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_personneCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "La personne a été ajoutée",
          });

          if (this.componentParent) {
            this.$root.$emit(this.componentParent, __.data.personneCreate);
          }

          this.$root.$emit("skeleton--close");
          this.$emit('personneCreate', __.data.personneCreate)
          this.init();
        });
    },

    init() {
      if (
        this.$route.path.startsWith("/annuaire/personnes/edit/") &&
        this.$route.params.id
      ) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_personne,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let personne = data.data.personne;
            this.input = JSON.parse(JSON.stringify(personne));
            // console.log(this.input)
          });
      } else {
        if (this.personneInfos && !_.isEmpty(this.personneInfos)) {
          this.input = JSON.parse(JSON.stringify(this.personneInfos))
        } else {
          this.input = JSON.parse(JSON.stringify(this.personne));
        }

        if (this.displayMailForm && this.input.adressesMails.length == 0) {
          this.input.adressesMails.push({
            adresse: "",
            newsletter: true,
          });
        }
        if (this.displayTelForm && this.input.numerosTelephone.length == 0) {
          this.input.numerosTelephone.push({
            indicatif: {
              name: "France",
              dialCode: "+33",
              code: "FR",
              flag: "🇫🇷",
            },
            numero: "",
            demarchage: true,
          });
        }
      }
    },

    //Permet de faire le ménage dans l'objet "Personne" afin d'en faire un objet "Input" compatible avec la définition en Backend
    //Permet de respecter le principe d'évolution (on peut ajouter sans cesse mais pas enlever de fonctionnalité)
    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));

      let numerosTelephone = [];
      for (let objNum of obj.numerosTelephone) {
        let inputNum = {
          indicatif: {
            name: objNum.indicatif.name,
            dialCode: objNum.indicatif.dialCode,
            code: objNum.indicatif.code,
            flag: objNum.indicatif.flag,
          },
          numero: objNum.numero,
          demarchage: objNum.demarchage,
        };
        numerosTelephone.push(inputNum);
      }
      let adressesMails = [];
      for (let objMail of obj.adressesMails) {
        let inputMail = {
          adresse: objMail.adresse,
          newsletter: objMail.newsletter,
        };
        adressesMails.push(inputMail);
      }
      let fonctions = [];
      for (let objFonction of obj.fonctions) {
        let inputFonction = {
          fonction: objFonction.fonction,
          entreprise:
            objFonction.entreprise && objFonction.entreprise.id
              ? objFonction.entreprise.id
              : objFonction.entreprise,
          etablissement:
            objFonction.etablissement && objFonction.etablissement.id
              ? objFonction.etablissement.id
              : objFonction.etablissement,
          pouvoirSignature: objFonction.pouvoirSignature,
        };
        fonctions.push(inputFonction);
      }
      let input = {
        id: obj.id,
        nom: obj.nom.toUpperCase(),
        prenom: obj.prenom,
        avatar: {
          id: obj.avatar && obj.avatar.id ? obj.avatar.id : "",
        },
        numerosTelephone: numerosTelephone,
        adressesMails: adressesMails,
        fonctions: fonctions,
        dateNaissance: obj.dateNaissance,
        civilite: obj.civilite
      };

      return input;
    },

    //Editer une personne existant
    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo.mutate({
        mutation: MUTATION_personneUpdate,
        variables: { input: toSend }
      })
        .then(__ => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "La personne a été éditée",
          });
          this.$root.$emit("skeleton--close");
          this.$emit('personneUpdate', __.data.personneUpdate)
          this.init();
        })
    },

    //Supprimer un champ adresse postale au formulaire
    removeAdressePostaleField(index) {
      this.input.adressesPostales.splice(index, 1);
    },

    //Supprimer un champ email au formulaire
    removeEmailField(index) {
      this.input.adressesMails.splice(index, 1);
    },

    removeFonctionField(index) {
      this.input.fonctions.splice(index, 1);
    },

    // Supprimer un champ téléphone au formulaire
    removePhoneField(index) {
      this.input.numerosTelephone.splice(index, 1);
    },

    updateAvatar(idAvatar) {
      this.input.avatar = {};
      this.input.avatar.id = idAvatar;
    },
  },

  watch: {
    personneInfos(val) {
      if (val && !_.isEqual(val, this.input) && !_.isEmpty(val)) {
        this.input = JSON.parse(JSON.stringify(val));
      }
    }
  }
};
</script>