var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',[_c('v-btn',{staticStyle:{"margin-bottom":"20px"},attrs:{"color":"primary"},on:{"click":_vm.importDefaultTemplates}},[_vm._v(" Importer tous les templates par défaut ")])],1),_c('v-col',[_c('v-btn',{staticStyle:{"margin-bottom":"20px"},attrs:{"color":"error"},on:{"click":_vm.deleteDefaultTemplates}},[_vm._v(" Supprimer tous les templates par défaut ")])],1)],1),_c('v-expansion-panels',{staticClass:"mb-6"},_vm._l((_vm.items),function(item){return _c('v-expansion-panel',{key:item.type},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"11"}},[_c('p',[_vm._v(" Liste des templates pour les objets de type "),_c('b',[_vm._v(_vm._s(item.type))])])]),_c('FichiersCounter',{attrs:{"customSearch":[
              { 'meta.typeParent': item.type },
              { 'meta.template': true },
            ]}})],1)],1),_c('v-expansion-panel-content',[_c('v-expansion-panels',{staticClass:"mb-6"},_vm._l((item.categories),function(categorie){return _c('v-expansion-panel',{key:categorie.value},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"11"}},[_c('p',[_vm._v(" Liste des templates pour les documents de type "),_c('b',[_vm._v(_vm._s(item.type)+" - "+_vm._s(categorie.value))])])]),_c('FichiersCounter',{attrs:{"customSearch":[
                    { 'meta.typeParent': item.type },
                    { 'meta.categorie': categorie.value },
                    { 'meta.template': true },
                  ]}})],1)],1),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',[_c('FichiersObject',{attrs:{"meta":{
                      typeParent: item.type,
                      categorie: categorie.value,
                      template: true,
                      toSplit: categorie.toSplit,
                    },"useCategory":false,"authorizedExtensions":['docx', 'doc']}})],1)],1)],1)],1)}),1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }