import gql from 'graphql-tag';
import { ASSET_FIELDS, ASSET_ORGANISATION_PARAMETRES_FIELDS, LIENSERVICE_FIELDS, FAMILLE_FIELDS, MODELE_FIELDS, MARQUE_FIELDS } from './fragments';

export const MUTATION_assetCreate = gql`
  mutation ($input: AssetInput!) {
    assetCreate(input: $input) {
      ...AssetFields
    }
  }${ASSET_FIELDS}
`;

export const MUTATION_assetsCreateFromFactureLignes = gql`
  mutation($input: [JSONObject]) {
    assetsCreateFromFactureLignes(input: $input)
  }
`;

export const MUTATION_assetUpdate = gql`
  mutation ($input: AssetInput!) {
    assetUpdate(input: $input) {
      ...AssetFields
    }
  }${ASSET_FIELDS}
`;

export const MUTATION_assetDelete = gql`
  mutation($id:ID!){
    assetDelete(id:$id){
      ...AssetFields
    }
  }${ASSET_FIELDS}
`;

export const MUTATION_assetOrganisationParametresUpdate = gql`
  mutation($input: AssetOrganisationParametresInput) {
    assetOrganisationParametresUpdate(input: $input) {
      ...AssetOrganisationParametresFields
    }
  }${ASSET_ORGANISATION_PARAMETRES_FIELDS}
`;

export const MUTATION_lienServiceCreate = gql`
  mutation ($input: LienServiceInput!) {
    lienServiceCreate(input: $input) {
      ...LienServiceFields
    }
  }${LIENSERVICE_FIELDS}
`;

export const MUTATION_lienServiceDelete = gql`
  mutation($id:ID!){
    lienServiceDelete(id:$id){
      ...LienServiceFields
    }
  }${LIENSERVICE_FIELDS}
`;

export const MUTATION_lienServiceUpdate = gql`
  mutation ($input: LienServiceInput!) {
    lienServiceUpdate(input: $input) {
      ...LienServiceFields
    }
  }${LIENSERVICE_FIELDS}
`;

export const MUTATION_lienServicesCreate = gql`
  mutation ($input: LienServicesInput){
    lienServicesCreate(input: $input) 
  }
`;

export const MUTATION_familleCreate = gql`
  mutation ($input: FamilleInput!) {
    familleCreate(input: $input) {
      ...FamilleFields
    }
  }${FAMILLE_FIELDS}
`;

export const MUTATION_familleUpdate = gql`
  mutation ($input: FamilleInput!) {
    familleUpdate(input: $input) {
      ...FamilleFields
    }
  }${FAMILLE_FIELDS}
`;

export const MUTATION_familleDelete = gql`
  mutation($id:ID!){
    familleDelete(id:$id){
      ...FamilleFields
    }
  }${FAMILLE_FIELDS}
`;

export const MUTATION_marqueCreate = gql`
  mutation ($input: MarqueInput!) {
    marqueCreate(input: $input) {
      ...MarqueFields
    }
  }${MARQUE_FIELDS}
`;

export const MUTATION_marqueUpdate = gql`
  mutation ($input: MarqueInput!) {
    marqueUpdate(input: $input) {
      ...MarqueFields
    }
  }${MARQUE_FIELDS}
`;

export const MUTATION_marqueDelete = gql`
  mutation($id:ID!){
    marqueDelete(id:$id){
      ...MarqueFields
    }
  }${MARQUE_FIELDS}
`;


export const MUTATION_modeleCreate = gql`
  mutation ($input: ModeleInput!) {
    modeleCreate(input: $input) {
      ...ModeleFields
    }
  }${MODELE_FIELDS}
`;

export const MUTATION_modeleUpdate = gql`
  mutation ($input: ModeleInput!) {
    modeleUpdate(input: $input) {
      ...ModeleFields
    }
  }${MODELE_FIELDS}
`;

export const MUTATION_modeleDelete = gql`
  mutation($id:ID!){
    modeleDelete(id:$id){
      ...ModeleFields
    }
  }${MODELE_FIELDS}
`;

export const MUTATION_modelesPatchDuplicate = gql`
  mutation($modelesDuplicate: [ID], $modeleOriginal: ID) {
    modelesPatchDuplicate(modelesDuplicate: $modelesDuplicate, modeleOriginal: $modeleOriginal) {
      id
    }
  }
`;