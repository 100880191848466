var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FormLayout',{attrs:{"icon":"mdi-cash-check","typeItem":"une demande d'accord","item":_vm.input,"disabled":_vm.input.affaire && _vm.input.bailleur && _vm.input.numeroDemande ? false : true},on:{"ajouter":_vm.ajouter,"modifier":_vm.modifier,"reinitialiser":_vm.init}},[_c('v-row',[_c('v-col',[_c('AffaireSelector',{attrs:{"disabled":_vm.affaire ? true : false},model:{value:(_vm.input.affaire),callback:function ($$v) {_vm.$set(_vm.input, "affaire", $$v)},expression:"input.affaire"}}),(_vm.input.affaire)?_c('AffaireObject',{attrs:{"affaireID":_vm.input.affaire.id ? _vm.input.affaire.id : _vm.input.affaire,"size":"xl"}}):_vm._e()],1),_c('v-col',[_c('BailleurSelector',{model:{value:(_vm.input.bailleur),callback:function ($$v) {_vm.$set(_vm.input, "bailleur", $$v)},expression:"input.bailleur"}}),_c('v-text-field',{attrs:{"label":"Numéro de la demande","prepend-icon":"mdi-counter","required":""},model:{value:(_vm.input.numeroDemande),callback:function ($$v) {_vm.$set(_vm.input, "numeroDemande", $$v)},expression:"input.numeroDemande"}}),_c('DateInput',{attrs:{"label":"Date de la demande"},model:{value:(_vm.input.dateDemande),callback:function ($$v) {_vm.$set(_vm.input, "dateDemande", $$v)},expression:"input.dateDemande"}}),_c('v-text-field',{attrs:{"label":"Montant de la demande","suffix":"€","prepend-icon":"mdi-cash","append-outer-icon":"mdi-auto-fix"},on:{"click:append-outer":function($event){return _vm.getMontantDemande()}},model:{value:(_vm.input.montantDemande),callback:function ($$v) {_vm.$set(_vm.input, "montantDemande", $$v)},expression:"input.montantDemande"}}),_c('v-select',{attrs:{"label":"Etat de la demande","prepend-icon":"mdi-progress-question","items":[
          { value: 'waiting', text: 'En cours' },
          {
            value: 'waitingForElmt',
            text: 'En attente d\'éléments complémentaires',
          },
          { value: 'valid', text: 'Validé' },
          { value: 'refused', text: 'Refusé' },
        ]},model:{value:(_vm.input.etat),callback:function ($$v) {_vm.$set(_vm.input, "etat", $$v)},expression:"input.etat"}}),(_vm.input.etat != 'waiting' && _vm.input.etat != 'refused')?[_c('v-text-field',{attrs:{"label":"Numéro d'accord","prepend-icon":"mdi-counter"},model:{value:(_vm.input.numeroAccord),callback:function ($$v) {_vm.$set(_vm.input, "numeroAccord", $$v)},expression:"input.numeroAccord"}}),_c('DateInput',{attrs:{"label":"Date de validité de l'accord"},model:{value:(_vm.input.dateValide),callback:function ($$v) {_vm.$set(_vm.input, "dateValide", $$v)},expression:"input.dateValide"}}),_c('v-text-field',{attrs:{"suffix":"€","label":"Loyers de l'accord","prepend-icon":"mdi-cash"},model:{value:(_vm.input.loyers),callback:function ($$v) {_vm.$set(_vm.input, "loyers", $$v)},expression:"input.loyers"}}),_c('v-text-field',{attrs:{"suffix":"%","label":"Taux de l'accord","prepend-icon":"mdi-percent","append-outer-icon":"mdi-auto-fix"},on:{"click:append-outer":function($event){return _vm.getTaux()}},model:{value:(_vm.input.taux),callback:function ($$v) {_vm.$set(_vm.input, "taux", $$v)},expression:"input.taux"}}),(_vm.input.taux > 0 && _vm.input.affaire)?_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.simulationExec}},[_vm._v(" Simulation des gains ")])],1),_c('v-col',[_vm._v(" Capital à céder : "),_c('MoneyLayout',{attrs:{"montant":_vm.simulation.capitalCession}})],1),_c('v-col',[_vm._v(" Spread : "),_c('MoneyLayout',{attrs:{"montant":_vm.simulation.spread}})],1)],1):_vm._e(),_c('ItemsTableForm',{attrs:{"title":"Liste des documents nécessaire à la cession","icon":"mdi-file-question"},model:{value:(_vm.input.documentsAFournir),callback:function ($$v) {_vm.$set(_vm.input, "documentsAFournir", $$v)},expression:"input.documentsAFournir"}})]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }