var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[(_vm.showSlot)?_c('v-card',{style:(_vm.slotStyle),attrs:{"height":_vm.cardHeight,"width":_vm.cardWidth,"flat":""}},[_vm._t("card-content")],2):_vm._e(),_c('GmapMap',{ref:"map",staticStyle:{"width":"100%","height":"60vh","margin":"0","padding":"0","overflow":"hidden"},attrs:{"center":this.center,"zoom":10,"map-type-id":"roadmap","options":{
      disableDefaultUI: true,
      zoomControl: true,
    }},on:{"dragstart":function($event){_vm.showSlot = false},"zoom_changed":function($event){_vm.showSlot = false},"click":function($event){_vm.showSlot = false},"tilesloaded":_vm.fitMap}},[(_vm.google)?[_c('GmapCluster',{attrs:{"zoomOnClick":true,"maxZoom":18}},[_vm._l((_vm.adresses),function(adresse,index){return [(adresse.latitude && adresse.longitude)?_c('GmapMarker',{key:index,ref:'map' + index,refInFor:true,attrs:{"position":{
              lat: parseFloat(adresse.latitude),
              lng: parseFloat(adresse.longitude),
            },"icon":adresse.icon
                ? {
                    url: adresse.icon,
                    scaledSize: new _vm.google.maps.Size(44, 44),
                  }
                : null},on:{"click":function($event){return _vm.clickOnMarker(index, $event)}}}):_vm._e()]})],2)]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }